// import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Loader from './components/Loader';
import { lazy, Suspense, useEffect, useState } from 'react';
import { UAParser } from 'ua-parser-js';
import Cookies from 'js-cookie';


const EmployeeDashboard = lazy(() => import('./components/EmployeeDashboard'));
const Newloginpage = lazy(() => import('./components/Newloginpage'));
const ManageUsers = lazy(() => import('./components/ManageUsers'));
const AddUser = lazy(() => import('./components/AddUser'));
const EditUser = lazy(() => import('./components/EditUser'));
const RechargeReport = lazy(() => import('./components/RechargeReport'));
const EasyGoldReport = lazy(() => import('./components/EasyGoldReport'));
const TxnReport = lazy(() => import('./components/TxnReport'));
const NsdlPanReport = lazy(() => import('./components/NsdlPanReport'));
const EarningReport = lazy(() => import('./components/EarningReport'));
const AdminProfitReport = lazy(() => import('./components/AdminProfitReport'));
const ServicewiseTxnReport = lazy(() => import('./components/ServicewiseTxnReport'));
const NsdlForm = lazy(() => import('./components/utilityComponents/NsdlForm'));
const InitInsuranceReport = lazy(() => import('./components/InitInsuranceReport'));
const AdminInsuranceReport = lazy(() => import('./components/AdminInsuranceReport'));
const LedgerReport = lazy(() => import('./components/LedgerReport'));
const AdminBbpsReport = lazy(() => import('./components/AdminBbpsReport'));
const FundTransfer = lazy(() => import('./components/FundTransfer'));
const Apicommission = lazy(() => import('./components/Apicommission'));
const AddSlabs = lazy(() => import('./components/AddSlabs'));
const AddCommCharge = lazy(() => import('./components/AddCommCharge'));
const Usercommission = lazy(() => import('./components/Usercommission'));
const Admincommission = lazy(() => import('./components/Admincommission'));
const MastAdminComm = lazy(() => import('./components/MastAdminComm'));
const BbpsAdminComm = lazy(() => import('./components/BbpsAdminComm'));
const BbpsUserComm = lazy(() => import('./components/BbpsUserComm'));
const ServiceMast = lazy(() => import('./components/ServiceMast'));
const Operatormast = lazy(() => import('./components/Operatormast'));
const Packagemast = lazy(() => import('./components/Packagemast'));
const ChargeSetup = lazy(() => import('./components/ChargeSetup'));
const AddCharge = lazy(() => import('./components/AddCharge'));
const MovetobankPermissionTable = lazy(() => import('./components/MovetobankPermissionTable'));
const AddBankPermission = lazy(() => import('./components/AddBankPermission'));
const Profileupdate = lazy(() => import('./components/Profileupdate'));
const Changepaswrd = lazy(() => import('./components/Changepaswrd'));
const Dispute = lazy(() => import('./components/Dispute'));
const Editapicommison = lazy(() => import('./components/Editapicommison'));
const Commission = lazy(() => import('./components/Commission'));
const NsdlReceipt = lazy(() => import('./components/utilityComponents/NsdlReceipt'));
const AdminCouponReqReport = lazy(() => import('./components/AdminCouponReqReport'));
const AddUtiAgent = lazy(() => import('./components/AddUtiAgent'));
const AssignPackage = lazy(() => import('./components/AssignPackage'));
const UploadResellerDetail = lazy(() => import('./components/UploadResellerDetail'));

const RetailerServices = lazy(() => import('./retailer/RetailerServices'));
const Retailerdashboard = lazy(() => import('./retailer/Retailerdashboard'));
const MobileRecharge = lazy(() => import('./retailer/MobileRecharge'));
const RetailerNav = lazy(() => import('./retailer/RetailerNav'));
const NsdlPan = lazy(() => import('./retailer/NsdlPan'));
const RetailerEarningReport = lazy(() => import('./retailer/RetailerEarningReport'));
const RetailerTxnReport = lazy(() => import('./retailer/RetailerTxnReport'));
const RetailerNsdlPanReport = lazy(() => import('./retailer/RetailerNsdlPanReport'));
const RetailerRechargeReport = lazy(() => import('./retailer/RetailerRechargeReport'));
const RetailerInitInsuranceReport = lazy(() => import('./retailer/RetailerInitInsuranceReport'));
const InsuranceReport = lazy(() => import('./retailer/InsuranceReport'));
const BbpsReport = lazy(() => import('./retailer/BbpsReport'));
const AddBalance = lazy(() => import('./retailer/AddBalance'));
const DthRecharge = lazy(() => import('./retailer/DthRecharge'));
const UtiPan = lazy(() => import('./retailer/UtiPan'));
const CouponReqReport = lazy(() => import('./retailer/CouponReqReport'));
const Electricity = lazy(() => import('./retailer/Electricity'));
const Cable = lazy(() => import('./retailer/Cable'));
const Cabletv = lazy(() => import('./retailer/Cabletv'));
const Landline = lazy(() => import('./retailer/Landline'));
const FastTag = lazy(() => import('./retailer/FastTag'));
const Broadband = lazy(() => import('./retailer/Broadband'));
const BroadbandPostpaid = lazy(() => import('./retailer/BroadbandPostpaid'));
const MobilePostpaid = lazy(() => import('./retailer/MobilePostpaid'));
const DatacardPrepaid = lazy(() => import('./retailer/DatacardPrepaid'));
const Lic = lazy(() => import('./retailer/Lic'));
const Lpg = lazy(() => import('./retailer/Lpg'));
const Water = lazy(() => import('./retailer/Water'));
const Muncipility = lazy(() => import('./retailer/Muncipility'));
const GasBooking = lazy(() => import('./retailer/GasBooking'));
const TrafficChallan = lazy(() => import('./retailer/TrafficChallan'));
const BbpsInsurance = lazy(() => import('./retailer/BbpsInsurance'));
const CreditCardPay = lazy(() => import('./retailer/CreditCardPay'));
const EmiPayment = lazy(() => import('./retailer/EmiPayment'));
const Emi = lazy(() => import('./retailer/Emi'));
const FeePayment = lazy(() => import('./retailer/FeePayment'));
const LoanRePayment = lazy(() => import('./retailer/LoanRepayment'));


const DashBoard = lazy(() => import('./masterAdmin/DashBoard'));
const DownlineUser = lazy(() => import('./masterAdmin/DownlineUser'));
const KycReport = lazy(() => import('./masterAdmin/KycReport'));
const MastAdminNav = lazy(() => import('./masterAdmin/MastAdminNav'));
const MatmReport = lazy(() => import('./masterAdmin/MatmReport'));
const AepsReport = lazy(() => import('./masterAdmin/AepsReport'));
const PanReport = lazy(() => import('./masterAdmin/PanReport'));
const MastAdminInsuranceReport = lazy(() => import('./masterAdmin/MastAdminInsuranceReport'));
const MastAdminEarningReport = lazy(() => import('./masterAdmin/MastAdminEarningReport'));
const MastAdminRechargeReport = lazy(() => import('./masterAdmin/MastAdminRechargeReport'));
const MastAdminBbpsReport = lazy(() => import('./masterAdmin/MastAdminBbpsReport'));
const MastAdminUserCommission = lazy(() => import('./masterAdmin/MastAdminUserCommission'));
const NewNav = lazy(() => import('./masterAdmin/NewNav'));
const ChangePassword = lazy(() => import('./masterAdmin/ChangePassword'));
const ViewProfile = lazy(() => import('./masterAdmin/ViewProfile'));
const MastAdminBbpsUserComm = lazy(() => import('./masterAdmin/MastAdminBbpsUserComm'));
const TransactionReport = lazy(() => import('./masterAdmin/TransactionReport'));
const AadharpayReport = lazy(() => import('./masterAdmin/AadharpayReport'));
// const UserTxnActivity = lazy(() => import('./masterAdmin/UserTxnActivity'));
const MastAdminCouponReqReport = lazy(() => import('./masterAdmin/MastAdminCouponReqReport'));
const DmtReport = lazy(() => import('./masterAdmin/DmtReport'));

const LazyToastContainer = lazy(() => import('./LazyToastContainer'));


function App() {

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  if (Cookies.get('deviceId') === undefined) {
    var fingerprint = "";
    const uaparser = new UAParser();
    fingerprint += navigator.userAgent;
    fingerprint += navigator.language;
    fingerprint += window.screen.colorDepth;
    fingerprint += window.screen.height;
    fingerprint += window.screen.width;
    fingerprint += uaparser.getResult().os.name;
    Cookies.set('deviceId', fingerprint, { expires: 1 });
  }

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);
  // }, []);

  const [deviceID, setDeviceID] = useState('');

  useEffect(() => {
    // Check if device ID and timestamp already exist in local storage
    let storedDeviceID = localStorage.getItem('deviceID');
    let storedTimestamp = localStorage.getItem('deviceIDTimestamp');

    const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // 1 day in milliseconds

    if (!storedDeviceID || !storedTimestamp || Date.now() - storedTimestamp > oneDayInMilliseconds) {
      // Generate a new device ID if it doesn't exist or if it's older than one day
      storedDeviceID = generateDeviceID();
      storedTimestamp = Date.now();
      localStorage.setItem('deviceID', storedDeviceID);
      localStorage.setItem('deviceIDTimestamp', storedTimestamp);
    }

    setDeviceID(storedDeviceID);
  }, []);

  const generateDeviceID = () => {
    // Generate a unique device ID
    return 'device-' + Math.random().toString(36).substring(2, 9);
  };

  return (
    <BrowserRouter>
      <Suspense fallback={<div><Loader /></div>}>
        <LazyToastContainer />

        <Routes>
          <Route path='/' element={<Newloginpage />} />
          <Route path='/employeedashboard' element={<EmployeeDashboard />} />
          <Route path='/manageusers' element={<ManageUsers />} />
          <Route path='/adduser' element={<AddUser />} />
          <Route path='/edituser' element={<EditUser />} />
          <Route path='/rechargereport' element={<RechargeReport />} />
          <Route path='/easygoldreport' element={<EasyGoldReport />} />
          <Route path='/txnreport' element={<TxnReport />} />
          <Route path='/nsdlpanreport' element={<NsdlPanReport />} />
          <Route path='/earningreport' element={<EarningReport />} />
          <Route path='/adminprofitreport' element={<AdminProfitReport />} />
          <Route path='/servicewisetxnreport' element={<ServicewiseTxnReport />} />
          <Route path='/nsdlform' element={<NsdlForm />} />
          <Route path='/initinsurancereport' element={<InitInsuranceReport />} />
          <Route path='/admininsurancereport' element={<AdminInsuranceReport />} />
          <Route path='/ledgerreport' element={<LedgerReport />} />
          <Route path='/adminbbpsreport' element={<AdminBbpsReport />} />
          <Route path='/fundtransfer' element={<FundTransfer />} />
          <Route path='/apicommison' element={<Apicommission />} />
          <Route path='/addslabs' element={<AddSlabs />} />
          <Route path='/addcommcharge' element={<AddCommCharge />} />
          <Route path='/usercommission' element={<Usercommission />} />
          <Route path='/admincommission' element={<Admincommission />} />
          <Route path='/masteradmincomm' element={<MastAdminComm />} />
          <Route path='/bbpsadmincomm' element={<BbpsAdminComm />} />
          <Route path='/bbpsusercomm' element={<BbpsUserComm />} />
          <Route path='/servicemast' element={<ServiceMast />} />
          <Route path='/operatormast' element={<Operatormast />} />
          <Route path='/packagemast' element={<Packagemast />} />
          <Route path='/chargesetup' element={<ChargeSetup />} />
          <Route path='/addcharge' element={<AddCharge />} />
          <Route path='/movetobankpermissiontable' element={<MovetobankPermissionTable />} />
          <Route path='/addbankpermission' element={<AddBankPermission />} />
          <Route path='/profileupdate' element={<Profileupdate />} />
          <Route path='/changepaswrd' element={<Changepaswrd />} />
          <Route path='/dispute' element={<Dispute />} />
          <Route path='/editapicommison' element={<Editapicommison />} />
          <Route path='/commission' element={<Commission />} />
          <Route path='/nsdlreceipt' element={<NsdlReceipt />} />
          <Route path='/admincouponreqreport' element={<AdminCouponReqReport />} />
          <Route path='/addutiagent' element={<AddUtiAgent />} />
          <Route path='/assignpackage' element={<AssignPackage />} />
          <Route path='/uploadresellerdetail' element={<UploadResellerDetail />} />


          {/* Retailer */}

          <Route path='/retailerservice' element={<RetailerServices />} />
          <Route path='/retailerdashboard' element={<Retailerdashboard />} />
          <Route path='/mobilerecharge' element={<MobileRecharge />} />
          <Route path='/retailernav' element={<RetailerNav />} />
          <Route path='/nsdlpan' element={<NsdlPan />} />
          <Route path='/retailerearningreport' element={<RetailerEarningReport />} />
          <Route path='/retailertxnreport' element={<RetailerTxnReport />} />
          <Route path='/retailerpanreport' element={<RetailerNsdlPanReport />} />
          <Route path='/retailerrechargereport' element={<RetailerRechargeReport />} />
          <Route path='/retailerinitinsurancereport' element={<RetailerInitInsuranceReport />} />
          <Route path='/insurancereport' element={<InsuranceReport />} />
          <Route path='/bbpsreport' element={<BbpsReport />} />
          <Route path='/addbalance' element={<AddBalance />} />
          <Route path='/dthrecharge' element={<DthRecharge />} />
          <Route path='/utipan' element={<UtiPan />} />
          <Route path='/couponreqreport' element={<CouponReqReport />} />
          <Route path='/electricity' element={<Electricity />} />
          <Route path='/cable' element={<Cable />} />
          <Route path='/cabletv' element={<Cabletv />} />
          <Route path='/landline' element={<Landline />} />
          <Route path='/fastag' element={<FastTag />} />
          <Route path='/broadband' element={<Broadband />} />
          <Route path='/broadbandpostpaid' element={<BroadbandPostpaid />} />
          <Route path='/mobilepostpaid' element={<MobilePostpaid />} />
          <Route path='/datacardprepaid' element={<DatacardPrepaid />} />
          <Route path='/lic' element={<Lic />} />
          <Route path='/lpg' element={<Lpg />} />
          <Route path='/water' element={<Water />} />
          <Route path='/muncipility' element={<Muncipility />} />
          <Route path='/gasbooking' element={<GasBooking />} />
          <Route path='/trafficchallan' element={<TrafficChallan />} />
          <Route path='/bbpsinsurance' element={<BbpsInsurance />} />
          <Route path='/creditcardpay' element={<CreditCardPay />} />
          <Route path='/emipayment' element={<EmiPayment />} />
          <Route path='/emi' element={<Emi />} />
          <Route path='/feepayment' element={<FeePayment />} />
          <Route path='/loanrepayment' element={<LoanRePayment />} />

          {/* Master Admin */}

          <Route path='/mastadminnav' element={<MastAdminNav />} />
          <Route path='/dashboard' element={<DashBoard />} />
          <Route path='/downlineuser' element={<DownlineUser />} />
          <Route path='/kycreport' element={<KycReport />} />
          <Route path='/matmreport' element={<MatmReport />} />
          <Route path='/aepsreport' element={<AepsReport />} />
          <Route path='/panreport' element={<PanReport />} />
          <Route path='/mastadmininsurancereport' element={<MastAdminInsuranceReport />} />
          <Route path='/mastadminearningreport' element={<MastAdminEarningReport />} />
          <Route path='/mastadminrechargereport' element={<MastAdminRechargeReport />} />
          <Route path='/mastadminbbpsreport' element={<MastAdminBbpsReport />} />
          <Route path='/newnav' element={<NewNav />} />
          <Route path='/mastadminusercommission' element={<MastAdminUserCommission />} />
          <Route path='/changepassword' element={<ChangePassword />} />
          <Route path='/viewProfile' element={<ViewProfile />} />
          <Route path='/mastadminbbpsusercomm' element={<MastAdminBbpsUserComm />} />
          <Route path='/transactionreport' element={<TransactionReport />} />
          <Route path='/aadharpayreport' element={<AadharpayReport />} />
          <Route path='/couponreqreport' element={<MastAdminCouponReqReport />} />
          {/* <Route path='/usertxnactivity' element={<UserTxnActivity />} /> */}
          <Route path='/dmtreport' element={<DmtReport />} />

        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
